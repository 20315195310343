export const getErrorMessage = (
  error: any | { detail: string } | null,
  message?: string,
  email = "support@aiplanet.com"
) => {
  if (error !== null) {
    let errorMessage;
    if (typeof error?.detail === "string") {
      errorMessage = error?.detail;
    } else if (error instanceof Object) {
      errorMessage = `${Object.values(error)[0]}`;
    } else {
      errorMessage = !!message
        ? message
        : `Error, Please try again, If error persists contact at ${email}.`;
    }
    return errorMessage;
  }
};
