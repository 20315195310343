import React from "react";
import { TextFieldProps } from "@mui/material";

import InputField from "./InputField";

const OutlinedInputWithStyles: React.FC<{
  type: string;
  name: string;
  value?: string | number;
  textFieldProps?: Omit<TextFieldProps, "fullWidth" | "variant">;
}> = ({ type, name, value, textFieldProps }) => {
  return (
    <InputField
      type={type}
      name={name}
      textFieldProps={{
        fullWidth: true,
        variant: "outlined",
        autoComplete: name,
        ...(textFieldProps || {}),
        sx: {
          "& .MuiInputBase-root": {
            fontSize: 14,
            padding: "8px",
          },
          "& .MuiOutlinedInput-input": {
            padding: "0px !important",
          },
          "& .MuiInputLabel-root": {
            top: value ? 0 : -8,
          },
          "& .Mui-focused": {
            top: 0,
          },
          "& .MuiFormHelperText-root": {
            fontSize: 12,
            ml: 0,
          },
          ...(textFieldProps?.sx || {}),
        },
      }}
    />
  );
};

export default OutlinedInputWithStyles;
