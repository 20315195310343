export const outlinedInputOverriddenStyles = (value: string) => ({
  "& .MuiOutlinedInput-root": {
    p: 1,
  },
  "& .MuiInputBase-root": {
    fontSize: 14,
    padding: "8px",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px !important",
  },
  "& .MuiInputLabel-root": {
    top: value ? 0 : -8,
  },
  "& .Mui-focused": {
    top: 0,
  },
  "& .MuiFormHelperText-root": {
    fontSize: 12,
    ml: 0,
  },
});
