/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { FieldAttributes, useField, useFormikContext } from "formik";
import { SxProps, useMediaQuery, useTheme } from "@mui/material";

import { countryCodeData } from "./../../utils/countryCode";

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

export const CustomPopper = function (props: any) {
  const theme = useTheme();

  const isDownSM = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Popper
      {...props}
      style={{ width: isDownSM ? 200 : 300 }}
      placement="bottom-start"
    />
  );
};

const SelectCountryCode: React.FC<
  FieldAttributes<{}> & { autocompleteSxProps?: SxProps }
> = ({ placeholder, autocompleteSxProps, ...inputProps }) => {
  const [field, meta] = useField<{}>(inputProps);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { values, setFieldValue } = useFormikContext<{
    country_code: string;
  }>();

  const getCountry = () => {
    if (values.country_code) {
      return countryCodeData.filter(
        (data) => data.phone === values.country_code
      )[0];
    }
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    // Internally, the autocomplete filter will check for the inputted text
    // in this string.
    stringify: (option: CountryType) => `${option.phone} ${option.label}`,
    trim: true,
  });

  return (
    <Autocomplete
      sx={{
        width: 100,
        '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
          padding: 2,
        },
        ...(autocompleteSxProps || {}),
      }}
      id="country-select-demo"
      onChange={(_, value) => {
        if (value) {
          setFieldValue("country_code", value.phone);
        }
      }}
      options={countryCodeData as CountryType[]}
      defaultValue={getCountry() || undefined}
      autoHighlight
      getOptionLabel={(option) => option.phone}
      filterOptions={filterOptions}
      renderOption={(props, option: CountryType) => (
        <ListItem
          sx={{ display: "flex", alignItems: "flex-start", columnGap: 2 }}
          {...props}
        >
          <Typography>{countryToFlag(option.code)}</Typography>
          <Typography variant="body2">
            {option.label} ({option.code}) +{option.phone}
          </Typography>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...field}
          variant="outlined"
          helperText={errorText}
          error={!!errorText}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
      PopperComponent={CustomPopper}
    />
  );
};

export default SelectCountryCode;
