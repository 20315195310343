import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import { Form } from "./types";

/**
 * Display a circular spinner on disabled=true
 */
const SpinnerButton: React.FC<Form.ISpinnerButtonProps & ButtonProps> = ({
  showDisableSpinner = true,
  boxProps,
  ...otherProps
}) => {
  const { disabled } = otherProps;

  return (
    <Box sx={{ position: "relative" }} {...(boxProps || {})}>
      <Button variant="contained" color="primary" size="medium" {...otherProps}>
        {otherProps.children}
        {disabled && showDisableSpinner && (
          <CircularProgress
            size={24}
            sx={{
              color: "green[500]",
              position: "absolute",
              top: "50%",
              left: "50%",
              mt: -3,
              ml: -3,
            }}
          />
        )}
      </Button>
    </Box>
  );
};

export default SpinnerButton;
