import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import { FieldAttributes, useField, useFormikContext } from "formik";
import { SxProps } from "@mui/material";

import { countryCodeData } from "./../../utils/countryCode";
import { countryToFlag, CustomPopper } from "./SelectCountryCode";

const SelectCountry: React.FC<
  FieldAttributes<{}> & {
    autocompleteSxProps?: SxProps;
    textFieldProps?: TextFieldProps;
  }
> = ({ placeholder, autocompleteSxProps, textFieldProps, ...inputProps }) => {
  const [field, meta] = useField<{}>(inputProps);
  const errorText = meta.error && meta.touched ? meta.error : "";
  const { values, setFieldValue } = useFormikContext<{
    country: string;
  }>();

  const getCountry = () => {
    if (values.country) {
      return countryCodeData.filter((data) => data.label === values.country)[0];
    }
  };
  const filterOptions = createFilterOptions({
    ignoreCase: true,
    // Internally, the autocomplete filter will check for the inputted text
    // in this string.
    stringify: (option: CountryType) => `${option.label}`,
    trim: true,
  });

  return (
    <Autocomplete
      sx={{
        ...(autocompleteSxProps || {}),
      }}
      id="selectCountry"
      onChange={(_, value) => {
        if (value) {
          setFieldValue("country", value.label);
        }
      }}
      options={countryCodeData as CountryType[]}
      defaultValue={getCountry() || undefined}
      autoHighlight
      getOptionLabel={(option) => option.label}
      filterOptions={filterOptions}
      renderOption={(props, option: CountryType) => (
        <ListItem
          sx={{ display: "flex", alignItems: "flex-start", columnGap: 2 }}
          {...props}
        >
          <Typography>{countryToFlag(option.code)}</Typography>
          <Typography variant="body2">{option.label}</Typography>
        </ListItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...field}
          variant="outlined"
          {...(textFieldProps || {})}
          helperText={errorText}
          error={!!errorText}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
      PopperComponent={CustomPopper}
    />
  );
};

export default SelectCountry;
