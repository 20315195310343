import React from "react";
import { useFormikContext } from "formik";
import Box, { BoxProps } from "@mui/material/Box";

import SelectCountry from "@packages/skillspace/src/common/components/form/SelectCountry";

import OutlinedInputWithStyles from "../../../common/components/form/input/OutlinedInputWithStyles";
import { outlinedInputOverriddenStyles } from "../../../common/components/form/input/helpers";
import { IFormFieldItem } from "../accountsTypes";

const RenderFormFields: React.FC<{
  fields: IFormFieldItem[];
  boxProps?: BoxProps;
}> = ({ fields, boxProps }) => {
  const { values } = useFormikContext<{ [key: string]: string }>();

  return (
    <Box
      {...(boxProps || {})}
      sx={{ display: "grid", rowGap: 4, ...(boxProps?.sx || {}) }}
    >
      {fields.map(({ type, name, label, disabled }) => {
        switch (type) {
          case "email":
          case "text":
          case "password":
            return (
              <OutlinedInputWithStyles
                key={name}
                type={type}
                name={name}
                value={values[name]}
                textFieldProps={{
                  label,
                  "aria-label": label,
                  disabled: !!disabled,
                  sx: {
                    "& .MuiInputBase-input": {
                      cursor: disabled ? "no-drop" : undefined,
                    },
                  },
                }}
              />
            );
          case "country":
            return (
              <SelectCountry
                key={name}
                type={type}
                name={name}
                value={values[name]}
                textFieldProps={{ label, "aria-label": label }}
                autocompleteSxProps={{
                  ...outlinedInputOverriddenStyles(values[name]),
                }}
              />
            );
          default:
            return <></>;
        }
      })}
    </Box>
  );
};

export default RenderFormFields;
